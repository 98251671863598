// Variables
// =============================================================================
$backdrop-background-dark: transparentize($color: #000000, $amount: 0.2);
$backdrop-background-light: transparentize($color: #000000, $amount: 0.6);


// Styling
// =============================================================================
.iris-backdrop {
	overflow: hidden;

	&::before {
		content: '';
		cursor: default;
		display: block;
		inset: 0;
		opacity: 0;
		position: fixed;
		transition: 300ms all linear;
		z-index: z-index('backdrop');
	}

	&--dark::before {
		background-color: $backdrop-background-dark;
	}

	&--light::before {
		background-color: $backdrop-background-light;
	}
}

.iris-backdrop--open::before {
	opacity: 1;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
body.no-scroll {
	overflow: hidden;
}
