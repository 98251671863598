// Variables
// =============================================================================

// Background color variables
$marquee-background: $colorPlatformGray100;

// Border variables
$marquee-border: $colorPlatformGray100;


// Example HTML markup
// =============================================================================
// 	<div class="iris-marquee__row">
// 		<h1 class="iris-marquee__title">Widget Title</h1>
//
// 		<div class="iris-marquee__action-group">
// 			<button class="iris-button iris-button--action" aria-label="Example Button">
// 				<span class="fa fa-newspaper-o iris-button__icon" aria-hidden="true"></span>
// 			</button>
//
// 			<button class="iris-button iris-button--action">Example Button</button>
// 		</div>
// 	</div>


// Base styling
// =============================================================================
.iris-marquee {
	background: $marquee-background;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.iris-marquee__row {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;

	// Space out the title row
	&:first-child {
		padding: 20px 15px;
	}

	// This is when there is only one row in the marquee
	&:only-child {
		border-bottom: 1px solid $marquee-border;
	}
}

.iris-marquee__action-group {
	align-items: center;
	display: flex;
}

.iris-marquee__title {
	margin: 0;
}
