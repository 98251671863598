// Component Variables
// =============================================================================

// Margin variables
$day-margin-bottom: 1.6rem !default;
$day-margin-left: 0.7rem !default;
$day-event-margin-top: 5px !default;

// Width variables
$calendar-days-width: calc(100% / 7) !default;

// Text variables
$day-font-size: 1.8rem !default;
$day-text-color: $colorPlatformGray800 !default;
$day-text-color-past: $colorPlatformGray500 !default;
$day-text-color-today: $colorPlatformGray800 !default;
$day-font-weight-today: 700 !default;
$day-event-label-text-color: $colorPlatformGray500 !default;

// Misc. variables
$day-container-background: $colorPlatformWhite !default;
$day-container-hover-background: $colorPlatformGray50 !default;
$day-container-border-color: $colorPlatformGray100 !default;
$day-event-size: 5px !default;
$day-avatar-dimension: 2.6rem !default;

// Example HTML
// =============================================================================
// <div class="iris-day iris-day--active iris-day--today" data-event>
//     <span class="iris-day__number">19</span>
// </div>


// Base styles
.iris-day {
	align-items: normal;
	background-color: $day-container-background;
	border: 1px solid $day-container-border-color;
	border-radius: 3px;
	color: $day-text-color;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 84px;
	justify-content: space-between;
	margin: 1px;
	overflow: hidden;
	padding: 5px;
	position: relative;
	text-align: left;
	transition: 0.3s;
	width: $calendar-days-width;
	z-index: z-index('calendar-day');

    &:first-child {
        margin-left: 0;
    }

	&:last-child {
		margin-right: 0;
	}

	.iris-day__button {
		background-color: transparent;
		border-style: none;
		color: inherit;
		cursor: pointer;
		font-weight: inherit;
		height: 100%;
		padding: 0;
		width: 100%;
	}

	.iris-day__button-wrapper {
		align-items: flex-start;
		color: inherit;
		display: flex;
		flex-direction: column;
		flex-grow: 2;
		font-weight: inherit;
		height: 100%;
		justify-content: space-between;
	}

	.iris-avatar[data-size='xsmall'] {
		// Move the avatar to the left to offset the white border on the avatar stack
		left: -2px;

		.iris-avatar__inner {
			height: $day-avatar-dimension;
			width: $day-avatar-dimension;
		}
	}
}

// Elements inside calendar Day
.iris-day__number {
	font-size: $day-font-size;
	padding-bottom: 0.5rem;
}

.iris-day__label {
	color: $day-event-label-text-color;
	flex-grow: 2;
	font-size: 1.2rem;
	font-weight: initial;
	text-align: left;
}

.iris-day__avatar {
	.iris-avatar__inner {
		border: 2px solid #FFFFFF;
	}

	&::before,
	&::after {
		border: 2px solid #FFFFFF;
		border-radius: 100%;
		display: block;
		height: $day-avatar-dimension;
		position: absolute;
		top: 0;
		width: $day-avatar-dimension;
	}

	&[data-stack]::before {
		content: '';
		transform: translateX(20%);
		z-index: z-index('calendar-day-stack-icon');
	}

	&[data-stack='3']::after {
		content: '';
		transform: translateX(40%);
		z-index: z-index('calendar-day-three-stack-icons');
	}
}

// Ripple Animation
.ripple {
	animation: ripple-effect 0.3s linear;
	animation-fill-mode: forwards;
	border-radius: 50%;
	display: block;
	height: 10px;
	position: absolute;
	transform: scale(0, 0);
	width: 10px;
	z-index: z-index('calendar-ripple');
}

// States
.iris-day {
    // Hover state:
	&:hover:not(.disabled-day) {
		background-color: $day-container-hover-background;
    }

    // Past and Disabled state:
    &.iris-day--past {
		color: $day-text-color-past;
	}

	&.disabled-day {
		cursor: not-allowed;
	}

    .iris-day__button:disabled {
        color: $day-text-color-past;
        cursor: not-allowed;
    }

    // Today state:
    &.iris-day--today {
		color: $day-text-color-today;
        font-weight: $day-font-weight-today;
    }

	&.selected {
		box-shadow: 0 10px 20px rgb(51 51 51 / 50%);
		position: relative;
		z-index: z-index('calendar-day-selected');

		.iris-avatar__inner {
			border: none;
		}
	}
}

// Keyframe Animation
@keyframes ripple-effect {
	to {
		opacity: 1;
		transform: scale(3, 3);
	}
}
