// Component variables
// =============================================================================

// Border variables
$avatar-border-radius: 100% !default;

// Icon variables
$avatar-icon-size-xsmall: 1rem !default;
$avatar-icon-size-small: 1.6rem !default;
$avatar-icon-size-medium: 2.5rem !default;
$avatar-icon-size-large: 3rem !default;
$avatar-icon-size-xlarge: 4rem !default;

// Text variables
$avatar-text-size-xsmall: 1rem !default;
$avatar-text-size-small: 1.6rem !default;
$avatar-text-size-medium: 2rem !default;
$avatar-text-size-large: 2.4rem !default;
$avatar-text-size-xlarge: 3.2rem !default;
$avatar-font-weight: 700 !default;


// Example HTML markup
// =============================================================================
// <div class="iris-avatar" data-size="small">
//     <img class="iris-avatar__image" alt="Avatar" src="<URL>" />
// </div>
// <div class="iris-avatar" data-size="small" title="Home Costs">
//     <span class="iris-avatar__icon fa fa-home"></span>
// </div>


// Base component styling
// =============================================================================
.iris-avatar {
	border-radius: $avatar-border-radius;
	position: relative;
}

.iris-avatar__image {
	height: 100%;
}

.iris-avatar__text {
	font-weight: $avatar-font-weight;
	line-height: 1;
}

.iris-avatar__inner {
	align-items: center;
	border-radius: $avatar-border-radius;
	display: flex;
	justify-content: center;
	overflow: hidden;
}


// States:
// =============================================================================
.iris-avatar {
	// State: Editible
	&--editible {
		.iris-avatar__inner {
			position: relative;

			&::before {
				background-color: transparentize($colorPlatformGray800, 0.3);
				border-radius: 100%;
				content: '';
				inset: 0;
				position: absolute;
				z-index: z-index('avatar-inner');
			}
		}

		.iris-avatar__edit-button {
			border-radius: 100%;
			height: 100%;
			inset: 0;
			padding: 0.3rem;
			position: absolute;
			width: 100%;
			z-index: z-index('avatar-button');

			.iris-button__icon::before,
			.iris-button__icon::after {
				color: $colorPlatformWhite;
			}

			&:hover {
				.iris-button__icon::before,
				.iris-button__icon::after {
					color: darken($colorPlatformWhite, 20%);
				}
			}
		}
	}
}


// Options:
// =============================================================================
.iris-avatar {
	// Option: Extra Small
	&[data-size='xsmall'] {
		.iris-avatar__inner {
			height: 2rem;
			width: 2rem;
		}

		.iris-avatar__icon::before,
		.iris-avatar__icon::after {
			font-size: $avatar-icon-size-xsmall;
		}

		.iris-avatar__text {
			font-size: $avatar-text-size-xsmall;
		}

		&.iris-avatar--editible {
			.iris-button__icon::before,
			.iris-button__icon::after {
				font-size: $avatar-icon-size-xsmall;
			}
		}
	}

	// Option: Small
	&[data-size='small'] {
		.iris-avatar__inner {
			height: 4rem;
			width: 4rem;
		}

		.iris-avatar__icon::before,
		.iris-avatar__icon::after {
			font-size: $avatar-icon-size-small;
		}

		.iris-avatar__text {
			font-size: $avatar-text-size-small;
		}

		&.iris-avatar--editible {
			.iris-button__icon::before,
			.iris-button__icon::after {
				font-size: $avatar-icon-size-small;
			}
		}
	}

	// Options: Medium
	&[data-size='medium'] {
		.iris-avatar__inner {
			height: 5rem;
			width: 5rem;
		}

		.iris-avatar__icon::before,
		.iris-avatar__icon::after {
			font-size: $avatar-icon-size-medium;
			line-height: $avatar-icon-size-medium;
		}

		.iris-avatar__text {
			font-size: $avatar-text-size-medium;
		}

		&.iris-avatar--editible {
			.iris-button__icon::before,
			.iris-button__icon::after {
				font-size: $avatar-icon-size-medium;
			}
		}
	}

	&[data-size='large'] {
		.iris-avatar__inner {
			height: 6rem;
			width: 6rem;
		}

		.iris-avatar__icon::before,
		.iris-avatar__icon::after {
			font-size: $avatar-icon-size-large;
			line-height: $avatar-icon-size-large;
		}

		.iris-avatar__text {
			font-size: $avatar-text-size-large;
		}

		&.iris-avatar--editible {
			.iris-button__icon::before,
			.iris-button__icon::after {
				font-size: $avatar-icon-size-large;
			}
		}
	}

	&[data-size='xlarge'] {
		.iris-avatar__inner {
			height: 8rem;
			width: 8rem;
		}

		.iris-avatar__icon::before,
		.iris-avatar__icon::after {
			font-size: $avatar-icon-size-xlarge;
			line-height: $avatar-icon-size-xlarge;
		}

		.iris-avatar__text {
			font-size: $avatar-text-size-xlarge;
		}

		&.iris-avatar--editible {
			.iris-button__icon::before,
			.iris-button__icon::after {
				font-size: $avatar-icon-size-xlarge;
			}
		}
	}
}
