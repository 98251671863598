// Variables
// =============================================================================

// Text variables
$toggle-label-default: $colorPlatformGray800 !default;
$toggle-label-active: $colorBrandedInfo !default;
$toggle-label-inactive: $colorPlatformGray500 !default;

// Border variables
$toggle-inner-border-default: $colorPlatformGray100 !default;
$toggle-inner-border-active: $san-marino !default;
$toggle-inner-border-width: 0.5px !default;

// Box shadow variables
$toggle-box-shadow-default: 0 2px 3px 0 rgb(0 0 0 / 50%) !default;
$toggle-box-shadow-focus: 0 0 4px 2px $colorBrandedInfo !default;
$toggle-box-shadow-disabled: 0 0 4px 2px transparent !default;

// Background variables
$toggle-inner-background-default: $colorPlatformGray100 !default;
$toggle-inner-background-active: $colorBrandedInfo;
$toggle-inner-before-background-active: linear-gradient(to bottom, $colorBrandedInfo, $havelock-blue) !default;
$toggle-mechanism-background-default: $colorPlatformWhite !default;
$toggle-mechanism-background-active: $colorPlatformWhite !default;

// Padding variables
// This ensures the height matches the design. (11 x 2) x (20 x 2) = 22px x 40px
$toggle-inner-padding-default: 1.1rem 2rem !default;

// Font variables
$toggle-font-weight-inactive: $font-weight-standard !default;
$toggle-font-weight-active: $font-weight-standard !default;

// Offset variables
$toggle-mechanism-pixel-offset-off: 1px !default;
$toggle-mechanism-pixel-offset-on: 2px !default;

// Platform Variables and Mixin Imports
// =============================================================================
@import './partials/_toggle.android';

// Example Markup
// =============================================================================
// <div class="iris-toggle">
// 	  <input class="iris-toggle__input" type="checkbox" id="email_enabled" />
// 	  <label class="iris-toggle__label" for="email_enabled" aria-label="Enable Email Alerts">
//        <span class="iris-toggle__left" aria-hidden="true">On</span>
//        <span class="iris-toggle__inner"></span>
//        <span class="iris-toggle__right" aria-hidden="true">Off</span>
// 	  </label>
// </div>

// Base Styling
// =============================================================================
.iris-toggle__input {
	height: 1px;
	opacity: 0;
	position: absolute;
	width: 1px;

	// When the toggle is active, change the inner background and transition the inner sphere.
	&:checked ~ .iris-toggle__label {
		.iris-toggle__inner {
			background-color: $toggle-inner-background-active;
			border-color: $toggle-inner-border-active;

			// This is the psuedo element that has the linear gradient on it.
			// Since CSS transitions doesn't work with linear-gradients (as in the spec)
			// we are faking it with opacity.
			&::before {
				opacity: 1;
			}

			// Move the inner sphere to the right.
			&::after {
				background-color: $toggle-mechanism-background-active;
				transform: translateY(-50%) translateX(100%) translateX(- #{$toggle-mechanism-pixel-offset-on});
			}
		}

		// Also update the label colors.
		.iris-toggle__left {
			color: $toggle-label-inactive;
			font-weight: $toggle-font-weight-inactive;
		}

		.iris-toggle__right {
			color: $toggle-label-active;
			font-weight: $toggle-font-weight-active;
		}
	}

	&:focus ~ .iris-toggle__label .iris-toggle__inner {
		box-shadow: $toggle-box-shadow-focus;
	}
}

.iris-toggle__label {
	cursor: pointer;
	display: inline-block;
}

// Left and right labels for the toggle
.iris-toggle__left,
.iris-toggle__right {
	display: inline-block;
	text-transform: uppercase;
	transition: 200ms all ease-in-out;
	vertical-align: middle;
}

.iris-toggle__left {
	color: $toggle-label-default;
	font-weight: $toggle-font-weight-active;
}

.iris-toggle__right {
	color: $toggle-label-inactive;
	font-weight: $toggle-font-weight-inactive;
}

// The inner section of the toggle.
.iris-toggle__inner {
	background-color: $toggle-inner-background-default;
	border: $toggle-inner-border-width solid $toggle-inner-border-default;
	border-radius: 1.5rem;
	display: inline-block;
	margin: 0 10px;
	padding: $toggle-inner-padding-default;
	position: relative;
	transition: 200ms all ease-in-out;
	vertical-align: middle;

	// The gradient that will fade in via opacity
	&::before {
		background-image: $toggle-inner-before-background-active;
		border-radius: 1.5rem;
		content: '';
		display: block;
		left: 0;
		opacity: 0;
		padding: $toggle-inner-padding-default;
		position: absolute;
		top: 0;
		transition: 200ms all ease-in-out;
	}

	// The white sphere that makes up the actual toggle mechanism.
	&::after {
		background-color: $toggle-mechanism-background-default;
		border-radius: 50%;
		box-shadow: $toggle-box-shadow-default;
		content: '';
		display: block;
		height: 2rem;
		left: $toggle-mechanism-pixel-offset-off;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: 200ms all ease-in-out;
		width: 2rem;
	}
}

// States:
// =============================================================================
.iris-toggle {
	&--disabled {
		cursor: not-allowed;
	}

	&--disabled .iris-toggle__input,
	.iris-toggle__input:disabled {
		~ .iris-toggle__label {
			opacity: 0.35;
			pointer-events: none;

			// Change both labels to the default font color
			.iris-toggle__left,
			.iris-toggle__right {
				color: $toggle-label-default;
			}
		}

		&:focus ~ .iris-toggle__label .iris-toggle__inner {
			border-color: $toggle-inner-border-default;
			box-shadow: $toggle-box-shadow-disabled;
		}
	}
}
