// Variables
// =============================================================================

// Border variables
$switchboard-border: 1px solid $colorPlatformGray100 !default;
$switchboard-border-radius: 3.5rem !default;

// Text variables
$switchboard-font-size: 1.4rem !default;
$switchboard-label-text-color: $colorPlatformGray500 !default;

// Box shadow variables
$switchboard-label-box-shadow-active: 0 5px 20px -5px rgb(0 0 0 / 30%) !default;
$switchboard-box-shadow-focus: 0 0 4px 2px $colorBrandedInfo !default;

// Example HTML markup
// =============================================================================
// <div class="iris-switchboard">
//     <input id="list_view" class="iris-switchboard__input" type="radio" name="test" checked/>
//     <label for ="list_view" class="iris-switchboard__label"><i class="fa fa-list-ul" aria-hidden="true"></i>List View</label>
//     <input id="pie_chart" class="iris-switchboard__input" type="radio" name="test" />
// 	   <label for="pie_chart" class="iris-switchboard__label"><i class="fa fa-pie-chart" aria-hidden="true"></i>Pie Chart</label>
// 	   <input id="bar_chart" class="iris-switchboard__input" type="radio" name="test" />
// 	   <label for="bar_chart" class="iris-switchboard__label"><i class="fa fa-bar-chart" aria-hidden="true"></i>Bar Chart</label>
// </div>


// Styles
// =============================================================================
.iris-switchboard {
	border: $switchboard-border;
	border-radius: $switchboard-border-radius;
	display: inline-flex;
	height: 35px;
	position: relative;
}

.iris-switchboard__input {
	@include screenreader-only;
}

.iris-switchboard__label {
	align-items: center;
	background: transparent;
	border: none;
	border-radius: $switchboard-border-radius;
	color: $switchboard-label-text-color;
	cursor: pointer;
	display: flex;
	font-size: $switchboard-font-size;
	justify-content: center;
	line-height: $switchboard-font-size;
	padding: 12px 15px;
	position: relative;
	transition: 0.15s;
	z-index: 1;

	&::before {
		border-radius: $switchboard-border-radius;
		box-shadow: $switchboard-label-box-shadow-active;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transform: scale(0, 0);
		transition: 0.25s;
		width: 100%;
		z-index: -1;
	}

	.fa {
		margin-right: 10px;
	}
}

.iris-switchboard__input:checked + .iris-switchboard__label::before {
	opacity: 1;
	transform: scale(1, 1);
}

.iris-switchboard__input:focus:not(:checked) + .iris-switchboard__label::before,
.iris-switchboard__input:active:not(:checked) + .iris-switchboard__label::before {
	box-shadow: $switchboard-box-shadow-focus;
	opacity: 1;
	transform: scale(1, 1);
}

.iris-switchboard__input:focus:checked + .iris-switchboard__label::before,
.iris-switchboard__input:active:checked + .iris-switchboard__label::before {
	box-shadow: $switchboard-box-shadow-focus;
}
