$ada-focus-ring: $colorBrandedInfo !default;

// Vendor specific focus outline reset rules
::-moz-focus-inner {
	border: none;
}

.iris-mouse-use-detected {
	.iris-focus,
	*:focus {
		outline: none;
	}
}

.iris-keyboard-use-detected {
	.iris-focus,
	*:focus {
		box-shadow: 0 0 4px 2px $ada-focus-ring;
		outline: none;
	}
}

.iris-live-region {
	@include screenreader-only;
}
