// Component variables
// =============================================================================

// Border variables
$input-border: 1px solid $colorPlatformGray500 !default;
$input-border-error: $colorBrandedError !default;
$input-border-disabled: transparentize($colorPlatformGray500, 0.5) !default;
$input-border-readonly: transparent !default;

// Text variables
$input-font-size: 1.4rem !default;
$input-line-height: 1.25 !default;
$input-line-height-textarea: 1.6rem !default;
$input-text: $colorPlatformGray800 !default;
$input-text-placeholder: $colorPlatformGray500 !default;
$input-text-disabled: transparentize($colorPlatformGray800, 0.5) !default;
$input-text-readonly: $colorPlatformGray800 !default;

// Box shadow variables
$input-box-shadow-focus: $colorBrandedInfo !default;
$input-box-shadow-error: $colorBrandedError !default;
$input-box-shadow-disabled: none !default;

// Background variables
$input-background-readonly: transparent !default;

// Example HTML markup
// =============================================================================
// <div class="iris-textfield" data-tooltip-content="This input does this" aria-label="This input does this.">
//     <span class="sr-hide font-icon-add iris-textfield__icon" data-position="left"></span>
//     <input type="text" class="iris-textfield__input" />
//     <span class="sr-hide font-icon-add iris-textfield__icon" data-postion="right"></span>
// </div>

// Base component styling
// =============================================================================

// Base Parent Styles:
.iris-textfield {
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
}

// Base Input Styles:
.iris-textfield__input {
	background: $colorPlatformWhite;
	border: $input-border;
	border-radius: 3px;
	color: $input-text;
	display: block;
	font-size: $input-font-size;
	line-height: $input-line-height; // IE messes with line-height on inputs
	margin: 0;
	padding: 10px;
	transition: 200ms all linear;
	width: 100%;

	// Focus and active styles
	&:focus,
	&:active {
		box-shadow: 0 0 4px 2px $input-box-shadow-focus;
		outline: none;
	}

	// Placeholder text styles
	&::placeholder {
		color: $input-text-placeholder;
		opacity: 1;
	}

	// Remove clear button from IE and Edge
	&::-ms-clear {
		display: none;
	}
}

/* stylelint-disable */
// Set line height for text area
textarea.iris-textfield__input {
	height: 15rem;
	line-height: $input-line-height-textarea;
	resize: vertical;
}

// Set select input's height
select.iris-textfield__input {
	height: 39px;
}

/* stylelint-enable */
// Base Icon Styles:

.iris-textfield__icon {
	align-items: center;
	appearance: none;
	background: $colorPlatformWhite;
	border: 0;
	border-radius: 0;
	color: $input-text;
	display: flex !important;
	font-size: 1.6rem;
	line-height: 1.6rem;
	margin: 0;
	min-height: 22px;
	padding: 0;
	position: absolute;
	top: calc(50% - 11px);
}

// States:
// =============================================================================

// Generic Error Class
.iris-error-validation {
	.iris-textfield__input {
		border-color: $input-border-error;

		&:focus,
		&:active {
			box-shadow: 0 0 4px 2px $input-box-shadow-error;
		}
	}
}

.iris-textfield {
	// State: Error
	&--error {
		@extend .iris-error-validation;
	}

	// State: Disabled
	&--disabled {
		.iris-textfield__input {
			border-color: $input-border-disabled;
			color: $input-text-disabled;
			cursor: not-allowed;
			pointer-events: none;

			&:focus,
			&:active {
				box-shadow: $input-box-shadow-disabled;
			}

			// Placeholder text styles
			&::-webkit-input-placeholder,
			&:-moz-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder {
				color: $input-text-disabled;
			}
		}
	}

	// State: Readonly
	&--readonly {
		.iris-textfield__input {
			background-color: $input-background-readonly;
			border-color: $input-border-readonly;
			color: $input-text-readonly;
			cursor: text;
			pointer-events: none;

			&:focus,
			&:active {
				border-color: $input-border-readonly;
			}
		}
	}
}

// Options:
// =============================================================================
.iris-textfield {
	// Option: Inline
	&[data-display='inline'] {
		display: inline-block;
		width: auto;
	}

	// Option: Small
	&[data-size='small'] {
		width: 86px;
	}

	// Option: Medium
	&[data-size='medium'] {
		width: 145px;
	}

	// Option: Large
	&[data-size='large'] {
		width: 305px;
	}

	// Option: Compressed
	&[data-modifier='compressed'] {
		.iris-textfield__input {
			line-height: 1.3; // design requested input field match height of compressed button
			padding: 4px 10px;
		}
	}

	// Option: Icon will default on the left
	.iris-textfield__icon + .iris-textfield__input {
		padding-left: 35px;
	}

	.iris-textfield__icon:first-child {
		left: 10px;
	}

	&[data-icon-position='leading'] {
		.iris-textfield__icon {
			left: 10px;
			right: auto;
		}

		.iris-textfield__input {
			padding-left: 35px;
			padding-right: 10px;
		}
	}

	&[data-icon-position='trailing'] {
		.iris-textfield__icon {
			justify-content: flex-end;
			left: auto;
			right: 10px;
		}

		.iris-textfield__input {
			appearance: none;
			padding-left: 10px;
			padding-right: 35px;
		}
	}
}
