// Variables
// =============================================================================

// Background variables
$prompt-background-default: $colorPlatformWhite;

// Box shadow variables
$prompt-box-shadow-default: transparentize(#000000, 0.85);

// Margin variables
$prompt-margin-default: 20px;

// Padding variables
$prompt-padding-default: 30px;

// Text color variables
$prompt-body-color-primary: $colorPlatformGray800;
$prompt-body-color-secondary: $colorPlatformGray500;

// Width variables
$prompt-width-small: 350px;
$prompt-width-medium: 500px;
$prompt-width-large: 620px;
$prompt-width-xlarge: 900px;

// z-index variables
$prompt-z-index-default: z-index('prompt');


// Example HTML markup
// =============================================================================
// <div class="iris-prompt">
//  	<section class="iris-prompt__content" role="document">
//	 		<header class="iris-prompt__header">
//	 			<h2 class="iris-prompt__title">prompt Title</h2>
//	 			<button class="iris-button iris-button--ghost" data-boson="icon hidden-text compressed" data-close>
//	 				<span class="iris-button__icon fa fa-close" aria-hidden="true"></span>
//	 				Close
//	 			</button>
//	 		</header>
//
//	 		<div class="iris-prompt__body">
//	 			<p class="iris-prompt__body--primary">Primary text information goes here in 15px font in #333333.</p>
//	 			<p class="iris-prompt__body--secondary">Optional secondary text information would be 15px font in #707070.</p>
//	 		</div>
//
//	 		<footer class="iris-prompt__footer">
//	 			<button class="iris-button iris-button--secondary" data-close>Secondary</button>
//	 			<button class="iris-button iris-button--primary">Primary</button>
//	 		</footer>
//		</section>
// </div>


// Styling
// =============================================================================
.iris-prompt {
	background-color: $prompt-background-default;
	border-radius: 4px;
	box-shadow: 0 0 4px 0 $prompt-box-shadow-default;
	display: flex;
	flex-direction: column;
	left: 50%;
	opacity: 0;
	position: fixed;
	top: -100%;
	transform: translateY(0) translateX(-50%) scale(0.98, 0.98);
	transition: opacity 600ms ease-out, transform 600ms ease-out;
	width: $prompt-width-medium;
	z-index: -1;
}

.iris-prompt__content {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: 80vh;
}

.iris-prompt__header {
	align-items: center;
	display: flex;
	flex: 0 0 auto;
	justify-content: space-between;
	padding: $prompt-padding-default;

	+ .iris-prompt__body {
		padding-top: 0;
	}
}

.iris-prompt__title {
	margin: 0;
}

.iris-prompt__body {
	flex: 2 2 auto;
	font-size: 1.6rem;
	overflow: auto;
	padding: $prompt-padding-default;
	padding-bottom: 0;
	transition: 400ms all ease-out;
}

.iris-prompt__footer {
	align-items: center;
	border-radius: 0 0 4px 4px;
	display: flex;
	flex: 0 0 auto;
	justify-content: flex-end;
	padding: $prompt-padding-default;
}


// States
// =============================================================================
.iris-prompt {
	// The opening and closing animations are different for each variant.
	&--open {
		opacity: 1;
		top: 10vh;
		transform: translateY(0) translateX(-50%) scale(1, 1);
		z-index: $prompt-z-index-default;
	}

	// Closing state maintains the position while the prompt fades out.
	&--closing {
		top: 10vh;
		transform: translateY(0) translateX(-50%) scale(0.98, 0.98);
		z-index: $prompt-z-index-default;
	}
}


// Options
// =============================================================================
.iris-prompt {
	&[data-size='narrow'],
	&[data-size='small'] {
		width: $prompt-width-small;
	}

	&[data-size='wide'],
	&[data-size='medium'] {
		width: $prompt-width-medium;
	}

	&[data-size='large'] {
		width: $prompt-width-large;
	}

	&[data-size='xlarge'] {
		width: $prompt-width-xlarge;
	}
}

