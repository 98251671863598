// Variables
// =============================================================================

// Border variables
$checkbox-border-default: $colorPlatformGray500 !default;
$checkbox-border-checked: $colorPlatformGray500 !default;
$checkbox-border-hover: $colorPlatformGray800 !default;
$checkbox-border-focus: $colorBrandedInfo !default;
$checkbox-border-width-default: 1px !default;

// Box shadow variables
$checkbox-box-shadow-focus: $colorBrandedInfo !default;
$checkbox-box-shadow-disabled: transparent !default;

// Background variables
$checkbox-background-default: $colorPlatformWhite !default;
$checkbox-background-checked: $colorPlatformWhite !default;
$checkbox-background-size-default: 73% !default; // Roughly 16px

// Font variables
$checkbox-checkmark-color: $colorBrandedInfo !default;
$checkbox-description: $colorPlatformGray500 !default;

// Dimension variables
$checkbox-dimension-default: 22px !default;


// Platform Variables and Mixin Imports
// =============================================================================
@import './partials/_checkbox.android';


// Example HTML markup
// =============================================================================
// <div class="iris-checkbox">
//     <input class="iris-checkbox__input" type="checkbox" id="checkbox_example_1" aria-describedby="checkbox_example_1_description" />
//     <label class="iris-checkbox__label" for="checkbox_example_1">
//         <span class="iris-checkbox__content">Enable Email Notifications</span>
//     </label>
//     <div class="iris-checkbox__extended-content" >
//	        <span class="iris-checkbox__description" id="checkbox_example_1_description">Receive account information by text command. A list of commands will be sent to this number.</span>
//     </div>
// </div>

// Styling
// =============================================================================
.iris-checkbox__input {
	// Set the opacity, position, and size instead of display: none to receive focus
	height: $checkbox-dimension-default;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: absolute;
	width: $checkbox-dimension-default;
	z-index: -1;

	&:checked ~ .iris-checkbox__label::before {
		// TODO: pull this svg into a partial later
		background: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20viewBox%3D%220%200%20512%20512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m192%20432l-168.18-177.8c-10.904-11.146-10.707-29.02%200.438-39.924%2011.146-10.904%2029.02-10.707%2039.924%200.438%200.164%200.168%200.326%200.338%200.486%200.509l127.34%20136.78%20255.55-264.06c10.884-11.165%2028.759-11.392%2039.924-0.508s11.392%2028.759%200.508%2039.924l-295.98%20304.64z%22%20fill%3D%22%23#{ str-slice(color-to-hex($checkbox-checkmark-color),2) }%22%2F%3E%3C%2Fsvg%3E');
		background-color: $checkbox-background-checked;
		background-position: center;
		background-repeat: no-repeat;
		background-size: $checkbox-background-size-default;
		border-color: $checkbox-border-checked;
	}

	&:focus ~ .iris-checkbox__label::before {
		border-color: $checkbox-border-focus;
		box-shadow: 0 0 4px 2px $checkbox-box-shadow-focus;
	}
}

.iris-checkbox__label {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-size: 1.4rem;
	font-weight: 600;

	&::before {
		background-color: $checkbox-background-default;
		border: $checkbox-border-width-default solid $checkbox-border-default;
		border-radius: 3px;
		color: $checkbox-checkmark-color;
		content: '';
		display: block;
		flex-shrink: 0;
		height: $checkbox-dimension-default;
		line-height: $checkbox-dimension-default;

		// This is to add compatibility for the inverted decorator
		order: 0;
		text-align: center;
		transition: border-color 200ms linear, background-color 200ms linear;
		width: $checkbox-dimension-default;
	}

	&:hover::before {
		border-color: $checkbox-border-hover;
	}
}

.iris-checkbox__content {
	margin-left: 10px;
}

.iris-checkbox__extended-content {
	margin-left: 32px;
	margin-top: 5px;
}

.iris-checkbox__description {
	color: $checkbox-description;
	display: inline-block;
	font-size: 1.4rem;
}

// States
// =============================================================================
.iris-checkbox {
	&--disabled .iris-checkbox__input,
	.iris-checkbox__input:disabled {
		 ~ .iris-checkbox__label {
			cursor: not-allowed;
			opacity: 0.5;
			pointer-events: none;

			&:hover::before {
				border-color: $checkbox-border-default;
			}
		}

		&:focus ~ .iris-checkbox__label::before {
			border-color: $checkbox-border-default;
			box-shadow: $checkbox-box-shadow-disabled;
		}
	}
}

// Options
// =============================================================================
.iris-checkbox {
	// Option inverted
	&[data-modifier='inverted'] {
		.iris-checkbox__label {
			justify-content: flex-end;

			&::before {
				order: 1;
			}
		}

		.iris-checkbox__content {
			margin-left: 0;
			margin-right: 10px;
		}

		.iris-checkbox__extended-content {
			margin-left: 0;
			margin-right: 32px;
		}
	}
}
