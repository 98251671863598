// Component variables
// =============================================================================

// Text variables
$account-balance-font-size-primary-info: 1.6rem !default;
$account-balance-font-size-primary-info-small: 1.4rem !default;
$account-balance-font-size-secondary-info: 1.2rem !default;
$account-balance-text-primary-info: $colorPlatformGray800 !default;
$account-balance-text-secondary-info: $colorPlatformGray500 !default;
$account-balance-font-weight-primary-info: 500 !default;
$account-balance-font-weight-secondary-info: 400 !default;


// Example HTML Markup
// =============================================================================
// <div class="iris-account-balance iris-account-balance--refresh">
//     <div class="iris-account-balance__row">
//         <span class="iris-account-balance__icon fa fa-refresh"></span>
// 	       <span class="iris-account-balance__primary-info">$1,000.00</span>
// 	   </div>
// 	   <div class="iris-account-balance__row">
// 		   <span class="iris-account-balance__secondary-info">as of 24 JUL 2017</span>
// 	   </div>
// </div>


// Base Styles
// =============================================================================

// Base balance styles:
.iris-account-balance {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	justify-content: center;
}

.iris-account-balance__row {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	& ~ .iris-account-balance__row {
		margin-top: 5px;
	}
}

.iris-account-balance__primary-info {
	color: $account-balance-text-primary-info;
	font-size: $account-balance-font-size-primary-info;
	font-weight: $account-balance-font-weight-primary-info;
}

.iris-account-balance__secondary-info {
	color: $account-balance-text-secondary-info;
	font-size: $account-balance-font-size-secondary-info;
	font-weight: $account-balance-font-weight-secondary-info;
}

.iris-account-balance__icon {
	font-size: $account-balance-font-size-primary-info;
	margin-right: 5px;
}

.available-balance-icon::before {
	align-items: center;
	border: 1px solid $colorPlatformGray500;
	border-radius: 100%;
	color: $colorPlatformGray500;
	content: 'A';
	display: flex;
	font-size: 8px;
	height: 15px;
	justify-content: center;
	line-height: 15px;
	text-align: center;
	width: 15px;
}

.iris-account-balance {
	&--refresh {
		.iris-account-balance__icon {
			color: $colorBrandedInfo;
		}
	}

	&--pending {
		.iris-account-balance__icon {
			color: $colorPlatformGray500;
		}
	}

	&--error {
		.iris-account-balance__icon,
		.iris-account-balance__primary-info {
			color: $colorBrandedError;
		}
	}

	&--warning {
		.iris-account-balance__icon,
		.iris-account-balance__primary-info {
			color: $colorBrandedCaution;
		}
	}
}

// Decorators
// =============================================================================
.iris-account {
	&[data-size='small'] {
		.iris-account-balance__primary-info,
		.iris-account-balance__icon {
			font-size: $account-balance-font-size-primary-info-small;
		}
	}
}
