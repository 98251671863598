/* -- HEADINGS -- */
h1,
.h1 {
	font-size: 3.2rem;
	font-weight: 400;
}

h2,
.h2 {
	font-size: 2.4rem;
	font-weight: 400;
}

h3,
.h3 {
	font-size: 2rem;
	font-weight: 400;
}

h4,
.h4 {
	font-size: 1.8rem;
	font-weight: 400;
}

h5,
h6,
.h5,
.h6 {
	font-size: 1.6rem;
	font-weight: 400;
}

/* -- Inline Types -- */

a {
	background-color: transparent;
	color: $colorBrandedInfo;
	cursor: pointer;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $chambray;
	}
}

acronym {
	font-weight: 500;
}

b {
	font-weight: 700;
}

big {
	font-size: 1.25em;
}

code,
kbd,
tt,
var {
	font-family: monospace;
}

cite,
dfn,
em,
i,
var {
	font-style: italic;
}

ins,
u {
	text-decoration: underline;
}

/* -- Block Types -- */

address {
	font-style: italic;
}

p {
	line-height: 1.5;
}

pre {
	display: block;
	font-family: monospace;
	white-space: pre;
}

q {
	&::before,
	&::after {
		content: '"';
	}
}

small {
	font-size: 0.75em;
}

strong {
	font-weight: 600;
}

sub {
	font-size: 0.75em;
	vertical-align: sub;
}

sup {
	font-size: 0.75em;
	vertical-align: super;
}
