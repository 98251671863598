
// Norman, Our normalizing styles
// =============================================================================

// Reset and/or normalize styles, box-sizing definition, etc.
// =============================================================================
[class*='iris-'],
[class*='iris-']::before,
[class*='iris-']::after {
	box-sizing: border-box;
}

// HACK: Since we are not a border-box shop (Ugh...) we need to add border-box
// specifically to the bootstrap grid system.
// =============================================================================
.col,
.row,
.container,
.container-fluid,
[class*='col-'],
[class*='col-']::before,
[class*='col-']::after {
	box-sizing: border-box;
}

// HACK: DEV-60439 || To prevent affecting other icon font implementations, we
// are shimming Iris components to fix the icon height issues.
// TODO: Figure out how to integrate this fix into the ORB_fonts library.
// NOTE: The :not(body) selector is to prevent it triggering when the body
// element has .iris-mouse-use-detected or .iris-backdrop classes.
[class*='iris-']:not(body) [class*='font-icon-'] {
	&:empty {
		display: inline-block;

		&::before {
			display: block;
		}
	}

	&:not(:empty)::before {
		display: inline-block;
	}
}
