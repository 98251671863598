// Component Variables
// =============================================================================

// Border Variables
$record-border-color: $colorPlatformGray100;
$record-striped-background-color: $colorPlatformGray50 !default;
$record-striped-order: odd !default;


// Example HTML Markup
// =============================================================================
// <div class="iris-record">
// 		Stuff
// </div>


// Base Component Styles
// =============================================================================
.iris-record {
	align-items: center;

	// border-top: 1px solid $record-border-color;
	display: flex;
	justify-content: center;
	padding: 15px 0;
	width: 100%;

	&:not(:last-child),
	.iris-list-item:not(:last-child) & {
		border-bottom: 1px solid $record-border-color;
	}
}


// States
// =============================================================================
.iris-record {
	&--collapsable {
		border: 1px solid transparent;
		transition: margin 0.2s ease-in-out;

		&:not(:last-child) {
			border-bottom: 1px solid $record-border-color;
		}

		&:focus,
		&:hover {
			border: 1px solid $record-border-color;
			box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
		}

		&.iris-record--collapsable-expanded {
			border: 1px solid $record-border-color;
			box-shadow: 0 0 50px -5px rgb(0 0 0 / 30%);
			margin: 15px 0;

			& + .iris-record {
				border-top: 1px solid $record-border-color;
			}
		}
	}
}


// Decorators
// =============================================================================
.iris-record {
	&[data-spacing='compact'] {
		padding: 10px 0;
	}

	&[data-spacing='cozy'] {
		padding: 20px 0;
	}

	&[data-spacing='comfortable'] {
		padding: 30px 0;
	}
}


// Zebra striping
// =============================================================================
// <div class="striped">
// 		<div class="iris-record">
// 				Stuff
// 		</div>
// 		<div class="iris-record">
// 				Stuff
// 		</div>
// 		<div class="iris-record">
// 				Stuff
// 		</div>
// </div>

.striped,
.zebra {
	.iris-record:nth-of-type(#{$record-striped-order}) {
		background-color: $record-striped-background-color;
	}
}
