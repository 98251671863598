// Example HTML markup
// =============================================================================
// <div class="iris-example">
//     <span>Example</span>
// </div>


// Styles
// =============================================================================
.iris-compass {
	width: 100%;

	&__header {
		margin: 20px 0;
		width: 100%;
	}

	&__steps {
		margin: 20px 0;
		position: relative;
		width: 100%;
	}

	&__footer {
		align-items: center;
		display: flex;
		flex-shrink: 0;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: 100%;
	}
}

.iris-compass-step {
	display: none;
	width: 100%;

	&__body {
		margin: 20px 0 40px;
	}

	&__footer {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin: 20px 0;
	}

	&__header {
		margin: 20px 0;
	}
}

[data-compass-active='true'] {
	display: block;
}

[data-compass-active='false'] {
	display: none;
}

[data-compass-active] {
	&.iris-compass--active-false {
		display: none;
		opacity: 0;
	}

	&.iris-compass--transition-active-true {
		display: block;
		opacity: 0;
		transition: opacity 250ms ease-in;
	}

	&.iris-compass--transitioning-active-true {
		opacity: 1;
	}

	&.iris-compass--active-true {
		display: block;
		opacity: 1;
	}
}

.iris-drawer__back-button {
	margin-left: 0;
	overflow: hidden;
	width: 36px;

	&.iris-compass-active-false {
		padding: 6px 0;
		width: 0;
	}

	&.iris-compass--transition-active-true {
		display: block;
		padding: 6px 0;
		transition: width 250ms ease-in, padding 250ms ease-in;
		width: 0;
	}

	&.iris-compass--transitioning-active-true {
		opacity: 1;
		padding: 6px 12px;
		width: 36px;
	}

	&.iris-compass--active-true {
		opacity: 1;
		padding: 6px 12px;
		width: 36px;
	}
}
