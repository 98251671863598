// Variables
// =============================================================================

// Border variables
$dropdown-border: 1px solid $colorPlatformGray500 !default;
$dropdown-border-error: $colorBrandedError !default;
$dropdown-border-disabled: transparentize($colorPlatformGray500, 0.5) !default;
$dropdown-border-readonly: transparent !default;
$dropdown-option-border: $colorPlatformGray100;

// Text variables
$dropdown-font-size: 1.4rem !default;
$dropdown-text: $colorPlatformGray800 !default;
$dropdown-text-active: #000000 !default;
$dropdown-text-placeholder: $colorPlatformGray500 !default;
$dropdown-text-disabled: transparentize($colorPlatformGray800, 0.5) !default;
$dropdown-text-readonly: $colorPlatformGray800 !default;
$dropdown-line-height: 1.25 !default;
$dropdown-list-heading-font-size: 1.6rem !default;
$dropdown-list-heading-text: $colorPlatformGray800 !default;

// Background
$dropdown-option-selected: $colorPlatformGray50 !default;
$dropdown-backdrop-background: transparentize($color: #000000, $amount: 0.6) !default;
$dropdown-list-background: $colorPlatformWhite !default;

// Box shadow variables
$dropdown-box-shadow-focus: $colorBrandedInfo !default;
$dropdown-box-shadow-error: $colorBrandedError !default;
$dropdown-box-shadow-disabled: none !default;
$dropdown-box-shadow-selected-hovered-active: inset 1px 1px $colorPlatformGray500, inset -1px -1px $colorPlatformGray500;

// Example HTML markup
// =============================================================================
// <div class="iris-dropdown" name="dropdown">
//		<div class="iris-dropdown__display-list-container">
//			<div class="iris-dropdown__display-list-header">
//				<span class="iris-dropdown__display-list-header-title h2"></span>
//				<button class="iris-button iris-button--ghost" data-modifier="compressed" aria-label="Close List"><span class="font-icon-cancel-x" aria-hidden="true"></span></button>
//			</div>
//			<ul class="iris-dropdown__display-list">
//			</ul>
//		</div>
// 		<div class="iris-dropdown__selected-value">One</div>
// 		<ul class="iris-options-list">
// 			<li class="iris-option" data-value="1">One</li>
// 			<li class="iris-option" data-value="2">Two</li>
// 			<li class="iris-option" data-value="3">Three</li>
// 			<li class="iris-option" data-value="4">Four</li>
// 			<li class="iris-option" data-value="5">Five</li>
// 		</ul>
// </div>

// Platform Variables and Mixin Imports
// =============================================================================
@import './partials/_dropdown.android';
@import './partials/_dropdown.desktop';
@import './partials/_dropdown.ios';

// Base styling
// =============================================================================
.iris-dropdown {
	display: block;
	font-size: $dropdown-font-size;
	position: relative;
	width: 100%;

	.iris-chevron {
		cursor: pointer;
		pointer-events: none;
		position: absolute;
		right: 10px;
		top: 50%;
	}

	.iris-dropdown__capture-input {
		border-radius: 3px;
		display: block;
		inset: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		transition: 200ms all linear;
		width: 100%;
		z-index: -1;

		// Focus and active styles
		&:focus,
		&:active {
			& + .iris-dropdown__selected-value {
				box-shadow: 0 0 4px 2px $dropdown-box-shadow-focus;
				outline: none;
			}
		}
	}

	.iris-dropdown__selected-value {
		background: $colorPlatformWhite;
		border: $dropdown-border;
		border-radius: 3px;
		color: $dropdown-text;
		cursor: pointer;
		display: block;
		font-size: $dropdown-font-size;
		line-height: $dropdown-line-height;
		overflow: hidden;
		padding: 10px 30px 10px 10px;
		text-align: left;
		text-overflow: ellipsis;
		transition: 200ms all linear;
		white-space: nowrap;
		width: 100%;
	}

	.iris-dropdown__placeholder {
		color: $dropdown-text-placeholder;
	}

	.iris-option {
		align-items: center;
		border-bottom: 1px solid $dropdown-option-border;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		line-height: $dropdown-line-height;
		min-height: 35px;
		padding: 10px;
		position: relative;

		&[disabled] {
			opacity: 0.5;
		}
		
		&[data-active='true'],
		&:hover {
			color: $dropdown-text-active;
		}

		&[aria-selected='true'] {
			background: $dropdown-option-selected;
		}

		&[aria-selected='true'],
		&[data-active='true'],
		&:hover {
			box-shadow: $dropdown-box-shadow-selected-hovered-active;
		}

		.iris-option__selected-view {
			display: none;
		}
	}

	.iris-option__multiselect-checkbox-container {
		display: none;
	}

	.iris-options-list {
		display: none;
	}

	.iris-list-item__content {
		width: 100%;
	}

	.iris-dropdown__display-list-container {
		transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
	}

	&,
	&.iris-dropdown--collapsed {
		& .iris-dropdown__display-list-container {
			display: none;
		}
	}

	&.iris-dropdown--expanding,
	&.iris-dropdown--collapsing,
	&.iris-dropdown--expanded {
		.iris-dropdown__display-list-container {
			display: block;
		}

		.iris-dropdown__selected-value {
			box-shadow: 0 0 4px 2px $dropdown-box-shadow-focus;
			outline: none;
		}
	}
}

// States
// =============================================================================
.iris-dropdown {
	// State: Error
	&--error {
		.iris-dropdown__selected-value {
			border-color: $dropdown-border-error;
		}

		.iris-dropdown__capture-input {
			&:focus,
			&:active {
				& + .iris-dropdown__selected-value {
					box-shadow: 0 0 4px 2px $dropdown-box-shadow-error;
				}
			}
		}

		&.iris-dropdown--expanded {
			.iris-dropdown__selected-value {
				box-shadow: 0 0 4px 2px $dropdown-box-shadow-error;
			}
		}
	}

	// State: Disabled
	&--disabled {
		cursor: not-allowed;

		.iris-dropdown__selected-value {
			border-color: $dropdown-border-disabled;
			color: $dropdown-text-disabled;
			pointer-events: none;
		}

		.iris-dropdown__capture-input {
			&:focus,
			&:active {
				& + .iris-dropdown__selected-value {
					box-shadow: 0 0 4px 2px $dropdown-box-shadow-disabled;
				}
			}
		}

		// Placeholder text styles
		.iris-dropdown__placeholder {
			color: $dropdown-text-disabled;
		}
	}
}

// Decorators
// =============================================================================
.iris-dropdown {
	// Option: Inline
	&[data-display='inline'] {
		display: inline-block;
		width: auto;
	}

	// Option: Small
	&[data-size='small'] {
		width: 86px;
	}

	// Option: Medium
	&[data-size='medium'] {
		width: 145px;
	}

	// Option: Large
	&[data-size='large'] {
		width: 305px;
	}

	&[multiple] {
		.iris-option__multiselect-checkbox-container {
			align-items: center;
			bottom: 0;
			display: flex;
			left: 0;
			padding-left: 10px;
			position: absolute;
			top: 0;
			width: 32px;
		}

		.iris-option {
			min-height: 22px;
			padding-left: 42px;
		}
	}
}

.iris-dropdown__display-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

// ***********
// iris-option-hidden-content is used by iris-option.text to respect aria-hidden.
// ***********
.iris-option .iris-option-hidden-content {
	display: none;
}

@mixin dropdownMobileStyling {
	.iris-dropdown {
		&::before {
			background: $dropdown-backdrop-background;
			cursor: default;
			display: block;
			inset: 0;
			opacity: 0;
			position: fixed;
			transition: 300ms opacity linear;
			z-index: 1;
		}

		&.iris-dropdown--expanding,
		&.iris-dropdown--collapsing {
			&::before {
				content: '';
				opacity: 0;
			}

			& .iris-dropdown__display-list-container {
				max-height: 0;
				opacity: 0;
			}
		}

		&.iris-dropdown--expanded {
			&::before {
				content: '';
				opacity: 1;
			}

			& .iris-dropdown__display-list-container {
				max-height: 90vh;
				opacity: 1;
			}
		}

		.iris-dropdown__display-list-container {
			background: $dropdown-list-background;
			bottom: 0;
			left: 0;
			max-height: 90vh;
			position: fixed;
			width: 100%;
			z-index: 1;
		}

		.iris-dropdown__display-list-header {
			border-bottom: solid 1px $dropdown-option-border;
			display: flex;
			min-height: 70px;
		}

		.iris-dropdown__display-list-header-title {
			align-self: center;
			color: $dropdown-list-heading-text;
			font-size: $dropdown-list-heading-font-size;
			width: 100%;
		}

		.iris-dropdown__display-list {
			max-height: calc(90vh - 70px);
			overflow-y: scroll;
		}

		.iris-dropdown__display-list-close {
			color: $dropdown-list-heading-text;

			& *::before {
				font-size: 2rem;
			}
		}
	}
}

// Platform Styles
// =============================================================================
@if  $platform == 'android' {
	@include dropdownAndroidStyling;
} @else if $platform == 'desktop' {
	@include dropdownDesktopStyling;
} @else if $platform == 'ios' {
	@include dropdownIosStyling;
}
