// Component Variables
// =============================================================================

// Transition variables
$tabs-hover-transition-length: 0.25s !default;
$tabs-scrollable-transition-length: 0.5s !default;

// Dimension variables
$tabs-scrollable-overlay-width: 105px !default;

// Padding variables
$tabs-nav-button-padding: 10px 8px !default;

// Text variables
$tabs-nav-button-font-size: 1.1rem !default;
$tabs-nav-button-color: $colorPlatformGray500 !default;

// Background variables
$tabs-nav-button-hover-background: $colorPlatformGray100 !default;


// Example HTML
// =============================================================================
// <nav class="iris-tabs iris-tabs--main" aria-label="some tabs">
// 		<div class="iris-tabs__inner">
// 			<ul class="iris-tabs__list">
// 				<li class="iris-tabs__list-item">
// 					<a class="iris-tabs__link" href="#">Accounts</a>
// 				</li>
// 				<li class="iris-tabs__list-item ">
// 					<a class="iris-tabs__link active" href="#">Statements</a>
// 				</li>
// 				<li class="iris-tabs__list-item">
// 					<a class="iris-tabs__link" href="#">Tax Information</a>
// 				</li>
// 			</ul>
// 		</div>
// </nav>


// Base Styling
// ============================================================================
.iris-tabs {
	position: relative;
	width: 100%;

	.iris-tabs__inner {
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
	}

	.iris-tabs__list {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.iris-tabs__list-item {
		flex-shrink: 0;
		list-style: none;
		position: relative;
	}

	.iris-tabs__link {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-flow: row nowrap;
		text-decoration: none;
		transition: $tabs-hover-transition-length;
	}

	.iris-tabs__link-icon {
		padding-right: 5px;
	}

	.iris-tabs__nav-button {
		color: $tabs-nav-button-color;
        display: none;
		line-height: $tabs-nav-button-font-size;
		opacity: 0;
		padding: $tabs-nav-button-padding;
		pointer-events: none;
		position: absolute;
		top: calc(50%);
		transform: translateY(-50%);
		transition: $tabs-scrollable-transition-length;
		z-index: 10;

		.iris-button__icon {
			font-size: $tabs-nav-button-font-size;
			line-height: $tabs-nav-button-font-size;
		}

		&:hover {
			background-color: $tabs-nav-button-hover-background;
			color: darken($tabs-nav-button-color, 10%);
		}
	}

	.iris-tabs__next-button {
		right: 0;
	}

	.iris-tabs__back-button {
		left: 0;
	}

	&::after,
	&::before {
		content: '';
        display: none;
		height: 100%;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: $tabs-scrollable-transition-length;
		width: $tabs-scrollable-overlay-width;
		z-index: 1;
	}

	&::after {
		background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
		right: 0;
	}

	&::before {
		background: linear-gradient(90deg, rgb(255 255 255 / 100%) 0%, rgb(255 255 255 / 0%) 100%);
		left: 0;
	}

	&.scrollable-right {
		&::after {
			opacity: 1;
		}

		.iris-tabs__next-button {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&.scrollable-left {
		&::before {
			opacity: 1;
		}

		.iris-tabs__back-button {
			opacity: 1;
			pointer-events: auto;
		}
	}
}
