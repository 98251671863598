// FONT: font-style
// =============================================================================
.font-style--italic {
	font-style: italic !important;
}


// FONT: font-weight
// =============================================================================
.font-weight--100 {
	font-weight: 100 !important;
}

.font-weight--light,
.font-weight--200 {
	font-weight: 200 !important;
}

.font-weight--300 {
	font-weight: 300 !important;
}

.font-weight--normal,
.font-weight--400 {
	font-weight: 400 !important;
}

.font-weight--medium,
.font-weight--500 {
	font-weight: 500 !important;
}

.font-weight--600 {
	font-weight: 600 !important;
}

.font-weight--bold,
.font-weight--700 {
	font-weight: 700 !important;
}


// FONT: size
// =============================================================================
.font-size--xs {
	font-size: 1.4rem !important;
}

.font-size--sm {
	font-size: 1.6rem !important;
}

.font-size--md {
	font-size: 2rem !important;
}

.font-size--lg {
	font-size: 3rem !important;
}

.font-size--xl {
	font-size: 4rem !important;
}
