// Component variables
// =============================================================================

$pagination-background-direction-button-hover: $colorPlatformGray50 !default;
$pagination-background-direction-button: transparent !default;
$pagination-border-direction-button-hover: $colorPlatformGray500 !default;
$pagination-border-direction-button: transparent !default;
$pagination-text-direction-button-hover: $colorPlatformGray500 !default;
$pagination-text-direction-button: $colorPlatformGray500 !default;
$pagination-focus-color: $colorBrandedInfo !default;
$pagination-text-total-color: $colorPlatformGray500 !default;


// Example HTML Markup
// =============================================================================
// <nav class="iris-pagination">
//     <button class="iris-pagination--direction-button" aria-label="Go to previous page">
//         <span class="font-icon-angle-left"></span>
//     </button>
//     <button class="iris-pagination--page-button" aria-label="Go to page 1" data-page-index="0" data-current-page="true">1</button>
//     <button class="iris-pagination--page-button" aria-label="Go to page 2" data-page-index="1">2</button>
//     <button class="iris-pagination--direction-button" aria-label="Go to previous page">
//         <span class="font-icon-angle-right"></span>
//     </button>
// </nav>


// Base Styles
// =============================================================================

// Base pagination control styles:
.iris-pagination {
	align-items: center;
	display: flex;
}

// Base textfield styles:
.iris-pagination__textfield {
	width: 55px;

	.iris-textfield__input {
		font-size: 1.6rem;
		padding: 3px;
		text-align: center;

		/* stylelint-disable */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
		}

		&[type=number] {
    	-moz-appearance: textfield;
		}
	}
}

// Base dropdown styles:
.iris-pagination__dropdown {
	width: 70px;

	.iris-dropdown__selected-value {
		padding-bottom: 5px;
		padding-top: 5px;
	}
}

// Base Total styles:
.iris-pagination__total {
	color: $pagination-text-total-color;
	font-size: 1.6rem;
	margin-left: 10px;
	margin-right: 5px;
	white-space: nowrap;
}

// Base direction button styles:
.iris-pagination__direction-button {
	align-items: center;
	background-color: $pagination-background-direction-button;
	border: 1px solid $pagination-border-direction-button;
	border-radius: 3px;
	color: $pagination-text-direction-button;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	padding: 0;
	transition: 200ms all linear;

  &:hover {
    background-color: $pagination-background-direction-button-hover;
    border-color: $pagination-border-direction-button-hover;
    color: $pagination-text-direction-button-hover;
  }

	&:focus {
		box-shadow: 0 0 4px 2px $pagination-focus-color;
		outline: none;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			background-color: $pagination-background-direction-button;
			border: 1px solid $pagination-border-direction-button;
			color: $pagination-text-direction-button;
		}
	}
}

.iris-pagination__direction-button--first {
	margin-right: 5px;
}

.iris-pagination__direction-button--previous {
	margin-right: 10px;
}

.iris-pagination__direction-button--next,
.iris-pagination__direction-button--last {
	margin-left: 5px;
}
