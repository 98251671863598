
// Example Markup
// =============================================================================
// <fieldset class="iris-form-groupset">
//    <div class="iris-form-groupset__container">
//        <legend class="iris-form-groupset__label">Address</legend>
//        <div class="iris-form-groupset__groups"></div>
//    </div>
// </fieldset>

// Text variables
$form-label-text: $colorPlatformGray500 !default;
$form-label-font-size: 1.4rem !default;

.iris-form-groupset {
	border: 0;
	margin: 0;
	padding: 0;

	.iris-form-groupset__label {
		color: $form-label-text;
		font-size: $form-label-font-size;
		font-weight: 600;
		margin-bottom: 10px;
	}

	.iris-form-groupset__groups {
		width: 100%;
	}

	.iris-form-groupset__container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.iris-radio,
	.iris-checkbox {
		padding-bottom: 15px;
	}
}

@include breakpoint-min(md) {
	// All Form Labels are stacked in mobile.
	// Variants:
	.iris-form-groupset {
		&--inline {
			.iris-form-groupset__container {
				flex-direction: row;
				justify-content: space-between;

				& > .iris-form-groupset__label {
					min-width: 12rem;
					padding-right: 20px;
					text-align: left;
					width: 100%;
				}
			}
		}
	}

	// Options:
	.iris-form-groupset {
		// Option: Small
		&[data-size='small'] {
			.iris-form-groupset__container {
				justify-content: flex-start;

				& > .iris-form-groupset__groups {
					width: 100%;
				}

				& > .iris-form-groupset__label {
					min-width: auto;
					width: 86px;
				}
			}
		}

		// Option: Medium
		&[data-size='medium'] {
			.iris-form-groupset__container {
				justify-content: flex-start;

				& > .iris-form-groupset__groups {
					width: 100%;
				}

				& > .iris-form-groupset__label {
					min-width: auto;
					width: 145px;
				}
			}
		}

		// Option: Large
		&[data-size='large'] {
			.iris-form-groupset__container {
				justify-content: flex-start;

				& > .iris-form-groupset__groups {
					width: 100%;
				}

				& > .iris-form-groupset__label {
					min-width: auto;
					width: 305px;
				}
			}
		}
	}
}
