// Component variables
// =============================================================================
// font-size, font-weight, color, box shadow
$account-tile-background-color: $colorPlatformWhite;
$account-tile-content-footer-font-size: 1.2rem;
$account-tile-content-footer-text: $colorPlatformGray800;
$account-tile-box-shadow: 0 2px 15px -3px rgb(0 0 0 / 25%);

// Example HTML Markup
// =============================================================================
// <div class="iris-account-tile" data-color="account-color-5">
//     <div class="iris-account-tile__content">
//         <div class="iris-account iris-account--multi-line-confined">
//         </div>
//     </div>
//     <div class="iris-account-tile__content-footer">
//         Payment due: $310.12 on 15 DEC
//     </div>
// </div>


// Base Styles
// =============================================================================
.iris-account-tile {
    align-items: center;
    border-radius: 10px;
    box-shadow: $account-tile-box-shadow;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    transition: all 200ms linear;
    width: 100%;

    &::after {
        background-color: $account-tile-background-color;
        content: '';
        display: block;
        height: 100%;
        left: -25%;
        position: absolute;
        right: 0;
        transform: translate3d(0, 0, 0) skew(45deg, 0);
        transition: 150ms cubic-bezier(0.5, 0, 0.2, 1);
        width: 150%;
    }
}

.iris-account-tile__content {
    background-color: $account-tile-background-color;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 4px;
    overflow: hidden;
    padding: 7px;
    position: relative;
    transition: 0.5s;
    width: 100%;
    z-index: z-index('account-tile');
}

.iris-account-tile__content-footer {
    color: $account-tile-content-footer-text;
    font-size: $account-tile-content-footer-font-size;
    line-height: 16px;
    padding: 8px 0 0 10px;
}
