// Default Variables
// =============================================================================
$iris-loading-rotation-duration: 2s !default;
$iris-loading-dash-duration: 1.5s !default;
$iris-loading-color-duration: $iris-loading-dash-duration * 2 !default;

// Example HTML markup
// =============================================================================
// <span class="iris-loading" data-size="small" role="status" aria-label="loading">
//    <svg class="iris-loading-svg" viewBox="25 25 50 50">
//      <circle class="iris-loading-path" cx="50" cy="50" r="20" />
//    </svg>
// </span>

// Styles
// =============================================================================
.iris-loading {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  width: 16px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &[data-size='small'] {
    width: 16px !important;

    .iris-loading-path {
      stroke-width: 8;
    }
  }

  &[data-size='medium'] {
    width: 32px !important;

    .iris-loading-path {
      stroke-width: 6;
    }
  }

  &[data-size='large'] {
    width: 64px !important;

    .iris-loading-path {
      stroke-width: 4;
    }
  }
}

.iris-loading-svg {
  animation: iris-loading-rotate $iris-loading-rotation-duration linear infinite;
  height: 100%;
  inset: 0;
  margin: auto;
  position: absolute;
  stroke: currentcolor; // uses inherited color property
  transform-origin: center center;
  width: 100%;
}

.iris-loading-path {
  animation: iris-loading-dash $iris-loading-dash-duration ease-in-out infinite;
  fill: none;
  stroke-dasharray: 100, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 4;
}

// if both of these specific color classes are applied, assume color transition is wanted
.iris-loading.font-color--theme-primary.font-color--theme-secondary .iris-loading-path {
  animation: iris-loading-dash $iris-loading-dash-duration ease-in-out infinite, iris-loading-theme-colors $iris-loading-color-duration ease-in-out infinite;
}

@keyframes iris-loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes iris-loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes iris-loading-theme-colors {
  0%,
  100% {
    stroke: var(--font-color--theme-primary);
  }

  50% {
    stroke: var(--font-color--theme-secondary);
  }
}
