// Component variables
// =============================================================================

// Background variables
$button-background-action: $colorPlatformWhite !default;
$button-background-circular: $colorPlatformWhite !default;

// Border variables
$button-border: 1px solid !default;
$button-border-action: $colorPlatformGray500 !default;
$button-border-ghost: transparent !default;
$button-border-circular: $colorPlatformGray100 !default;
$button-border-radius-pill: 40px !default;

// Text variables
$button-font-size: 1.4rem !default;
$button-font-size-compressed: 1.4rem !default;
$button-font-size-hero-small: 1.8rem !default;
$button-font-size-hero-large: 2.4rem !default;
$button-font-weight: 400 !default;
$button-font-weight-action: 400 !default;
$button-text-action: $colorPlatformGray500 !default;
$button-text-ghost: $colorBrandedInfo !default;
$button-text-circular: $colorPlatformGray500 !default;

// Box shadow variables
$button-box-shadow-focus: $colorBrandedInfo !default;
$button-box-shadow-disabled: none !default;

// Hover variables
$button-action-background-hover: $colorPlatformGray50 !default;
$button-action-border-hover: $colorPlatformGray800 !default;
$button-action-text-hover: $colorPlatformGray800 !default;
$button-ghost-text-hover: darken($colorBrandedInfo, 10%) !default;
$button-circular-background-hover: $colorPlatformGray50 !default;
$button-circular-border-hover: $colorPlatformGray500 !default;
$button-circular-text-hover: $colorPlatformGray500 !default;

// Disabled variables
$button-action-background-disabled: mix($colorPlatformWhite, $button-background-action, 50%) !default;
$button-action-border-disabled: mix($colorPlatformWhite, $button-border-action, 50%) !default;
$button-action-text-disabled: mix($colorPlatformWhite, $button-text-action, 50%) !default;
$button-ghost-text-disabled: mix($colorPlatformWhite, $button-text-ghost, 50%) !default;

// Destructive Variables
$button-destructive-text: $colorBrandedError !default;
$button-destructive-hover-text: darken($colorBrandedError, 10%) !default;
$button-destructive-disabled-text: mix($colorPlatformWhite, $button-destructive-text, 50%) !default;

// Neutral Variables
$button-neutral-text: $colorPlatformGray500 !default;
$button-neutral-hover-text: darken($colorPlatformGray500, 10%) !default;
$button-neutral-disabled-text: mix($colorPlatformWhite, $button-destructive-text, 50%) !default;

// Example HTML markup
// =============================================================================
// <button class="iris-button iris-button--primary" data-size="full-width">
//     This is a button
// </button>

// Base component styling
// =============================================================================

// Base Button Styles:
.iris-button {
	align-items: center;
	border: $button-border;
	border-radius: 3px;
	cursor: pointer;
	display: inline-flex;
	font-size: $button-font-size;
	font-weight: $button-font-weight;
	justify-content: center;
	line-height: $button-font-size;
	padding: 12px 15px;
	transition: 200ms all linear;

	& + .iris-button {
		margin-left: 1rem;
	}
}

.iris-button__icon:not(:only-child) {
	&:first-child {
		margin-right: 5px;
	}

	&:last-child {
		margin-left: 5px;
	}
}

// Variants:
// =============================================================================
.iris-button {
	// Variant: Action
	&--action {
		background-color: $button-background-action;
		border-color: $button-border-action;
		color: $button-text-action;
		font-weight: $button-font-weight-action;
	}

	// Variant: Ghost
	&--ghost {
		background-color: transparent;
		border-color: $button-border-ghost;
		color: $button-text-ghost;
	}

	// Variant: Circular
	&--circular {
		background-color: $button-background-circular;
		border-color: $button-border-circular;
		border-radius: 2rem;
		color: $button-text-circular;
		flex-shrink: 0;
		font-size: 1.8rem;
		height: 4rem;
		padding: 0;
		width: 4rem;

		.iris-button__icon {
			display: block;
			margin: auto;
			text-align: center;
			width: 100%;
		}
	}
}

// States:
// =============================================================================
.iris-button {
	// State: Hover & Focus
	&:hover,
	&:focus {
		// Variant action hover & focus
		&.iris-button--action {
			background-color: $button-action-background-hover;
			border-color: $button-action-border-hover;
			color: $button-action-text-hover;
		}

		// Variant ghost hover & focus
		&.iris-button--ghost {
			color: $button-ghost-text-hover;
		}

		// Variant circular hover & focus
		&.iris-button--circular {
			background-color: $button-circular-background-hover;
			border-color: $button-circular-border-hover;
			color: $button-circular-text-hover;
		}
	}

	// State: Focus
	// Only show the focus ring on buttons when the keyboard is detected.
	.iris-keyboard-use-detected & {
		&:focus {
			box-shadow: 0 0 4px 2px $button-box-shadow-focus;
			outline: none;
		}
	}

	// State: Disabled
	&--disabled,
	&--disabled:hover,
	&--disabled:focus,
	&:disabled {
		cursor: not-allowed;
		pointer-events: none;

		// Variant action disabled
		&.iris-button--action {
			background-color: $button-action-background-disabled;
			border-color: $button-action-border-disabled;
			color: $button-action-text-disabled;
		}

		// Variant ghost disabled
		&.iris-button--ghost {
			color: $button-ghost-text-disabled;
		}

		// State focus disabled
		&:focus {
			box-shadow: $button-box-shadow-disabled !important;
		}
	}
}

// Options:
// =============================================================================
.iris-button {
	// Option: Full Width
	&[data-size*='full-width'] {
		width: 100%;

		& + .iris-button {
			margin-left: 0;
		}
	}

	// Option: Compressed
	&[data-modifier*='compressed'] {
		font-size: $button-font-size-compressed;
		padding: 6px 12px;
	}

	// Option: Pill
	&[data-modifier*='pill'] {
		border-radius: $button-border-radius-pill;
	}

	// Option: Destructive (only for ghost and circular button)
	&[data-modifier*='destructive'] {
		&.iris-button--circular,
		&.iris-button--ghost {
			color: $button-destructive-text;

			&.iris-button--hover,
			&:hover,
			&:focus {
				color: $button-destructive-hover-text;
			}

			&.iris-button--disabled,
			&.iris-button--disabled:hover,
			&.iris-button--disabled:focus,
			&:disabled {
				color: $button-destructive-disabled-text;
			}
		}

		&.iris-button--circular {
			border-color: $button-destructive-text;

			&:hover,
			&:focus {
				border-color: $button-destructive-text;
			}

			&:disabled,
			&:disabled:hover,
			&:disabled:focus {
				border-color: $button-destructive-disabled-text;
			}
		}
	}

	&[data-modifier*='neutral'] {
		&.iris-button--circular,
		&.iris-button--ghost {
			color: $button-neutral-text;

			&.iris-button--hover,
			&:hover,
			&:focus {
				color: $button-neutral-hover-text;
			}

			&.iris-button--disabled,
			&.iris-button--disabled:hover,
			&.iris-button--disabled:focus,
			&:disabled {
				color: $button-neutral-disabled-text;
			}
		}
	}
}
